@tailwind base;
@tailwind components;
@tailwind utilities;
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600&display=swap');

html{
  scroll-behavior: smooth;
}

body{
  font-family: 'Inter', sans-serif;
}

#img-1{
  background-image: url('https://plus.unsplash.com/premium_photo-1676747433317-de4e84df75ad?auto=format&fit=crop&q=80&w=1470&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D');
  opacity: 50%;
}


.mySwiper{
  height: 90vh;
  position: relative;
  background-color: black;
}

.test{
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);/** X-axis align **/
  z-index: 99;
  
}

.noScroll::-webkit-scrollbar { 
  display: none; 
} 